import React from 'react';

const UserBoard = (props) => {

  if(props.usertasks.tasks.length) {

    var itemclass = 'paused';
    props.usertasks.tasks.map((task, i) => {
      if(task.active) {
        itemclass = 'working';
      }
    })
    

    return (
      <div className={`flip-card item ${itemclass}`}>
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <h2>{ props.usertasks.name }</h2>
          </div>
          <div className="flip-card-back">
            <h2>{ props.usertasks.name }</h2>
            <div className='tasks'>
              {props.usertasks.tasks.map((task, i) => {       

                var taskclass = 'not-active';
                if(task.active) {
                  taskclass = 'active';
                }

                return (
                  <div className={`task ${taskclass}`}>
                    <strong><a target="_blank" href={`https://activecollab.javali.pt/projects/${task.project_id}`}>{ task.project }</a></strong>
                    <span><a target="_blank" href={`https://activecollab.javali.pt/projects/${task.project_id}/tasks/${task.task_id}`}>{ task.name }</a></span>
                    <strong>{ task.elapsed }</strong>
                </div>
                )                 
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
  else {
    return ( 
      <div className="flip-card item not-working">
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <h2>{ props.usertasks.name }</h2>
          </div>
          <div className="flip-card-back">
            <h2>{ props.usertasks.name }</h2>
            <div className="task zzz"></div>
          </div>
        </div>
      </div>
    )
  }

  
}

export default UserBoard;