import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import UserBoard from './Components/UserBoard';
import { Watch } from  'react-loader-spinner'
// import Notification from './firebase/Notification'
// import { w3cwebsocket as W3CWebSocket } from "websocket";


function App() {

  const [tasks, setTasks] = useState(0);
  const [reload, setReload] = useState(0);
  const [time, setTime] = useState(Date.now());

  // const ws = new WebSocket("wss://javalicounters.dev10.javali.pt");
  // ws.onopen = (event) => {
  //   console.log('WebSocket Client Connected');
  // };
  // ws.onmessage = function (event) {
  //   console.log(event);
  //   const json = JSON.parse(event.data);
  //   console.log(`[message] Data received from server: ${json}`);
  //   try {
  //     if ((json.event = "data")) {
  //       console.log(json.data);
  //     }
  //   } catch (err) {
  //     console.log(event.data);
  //   }
  // };

  // const client = new W3CWebSocket('wss://javalicounters.dev10.javali.pt');
  // client.onopen = () => {
  //   console.log('WebSocket Client Connected');
  // };

  useEffect(() => {
    if(tasks === 0 || reload === 1) {
      getTasks();
      setReload(0);
    }
    const interval = setInterval(() => {
      setTime(Date.now());
      setReload(1);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });



  const getTasks = async () => {
    fetch('https://activecollab.javali.pt/stopwatches/', {  
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          
          const usertasks = []
          Object.values(data).forEach((item) => {
            usertasks[item.name] = [];
            usertasks[item.name]['name'] = item.name;
            usertasks[item.name]['tasks'] = [];
            item.tasks.forEach((task) => {
              usertasks[item.name]['tasks'].push(task);
            })
          })
    
          setTasks( Object.values(usertasks) );
        });
      }
      else {
        console.log('error getting data');
      }

      return;
    });
  }

  if(tasks === 0) {
    return (
      <div className="counter-wrapper loading">
        <Watch height="300" width="300" radius="48" color="#ff0" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
      </div>
    );
  }
  else {
    return (
      <div className="counter-wrapper">
        {tasks.map((usertasks, i) => {       
          return (<UserBoard key={usertasks} usertasks={usertasks} />)                 
        })}
      </div>
    );
  }

  
  
}

export default App;
